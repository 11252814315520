import conversations from './sidebarItems/conversations';
import contacts from './sidebarItems/contacts';
import reports from './sidebarItems/reports';
import campaigns from './sidebarItems/campaigns';
import settings from './sidebarItems/settings';
import notifications from './sidebarItems/notifications';
import primaryMenu from './sidebarItems/primaryMenu';

export const getSidebarItems = (accountId, currentUser) => {
  let primaryMenuItens = primaryMenu(accountId);
  let secondaryMenuItens = [
    conversations(accountId),
    contacts(accountId),
    reports(accountId),
    campaigns(accountId),
    settings(accountId),
    notifications(accountId),
  ];

  primaryMenuItens = primaryMenuItens.filter(menuItem => {
    if(currentUser.type !== 'SuperAdmin') {
      if(['campaigns', 'helpcenter'].includes(menuItem.key)) {
        return false;
      }
    }

    return true;
  });

  secondaryMenuItens[0].menuItems = secondaryMenuItens[0].menuItems.filter(menuItem => {
    if(currentUser.type !== 'SuperAdmin') {
      if(['MENTIONED_CONVERSATIONS'].includes(menuItem.label)) {
        return false;
      }
    }

    return true;
  });

  secondaryMenuItens[4].menuItems = secondaryMenuItens[4].menuItems.filter(menuItem => {
    if(currentUser.type !== 'SuperAdmin') {
      if(['ACCOUNT_SETTINGS', 'AGENTS', 'TEAMS', 'INBOXES',
          'CUSTOM_ATTRIBUTES', 'AUTOMATION', 'AGENT_BOTS',
          'INTEGRATIONS', 'APPLICATIONS', 'AUDIT_LOGS', 'BILLING'].includes(menuItem.label)) {
        return false;
      }
    }

    return true;
  });

  return {
    primaryMenu: primaryMenuItens,
    secondaryMenu: secondaryMenuItens,
  }
};
